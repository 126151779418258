

#entete1 {
    background-color: #333399;
    width: auto;
    height: 20px;
}

#entete2 {
    text-align: center;
    padding-top: 5px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: #FFFFFF;
    width: 100%;
    height: auto;
    display: block;
    box-shadow: 1px 1px 4px 1px #FFF;
}

.font-size
{
background-color: hotpink;
    font-size: 18pt;
}

.logo{
    display:inline-block;

     width: auto;
     height: auto;

     


}


.formulaiare_calcul
{
text-align:center; margin-left:10px;    width:50%; display:inline-block; padding:10px;


}


.contact1{

float:left;   text-align:center; margin-left:10px; padding:20px;    width:500px ;margin-top:20px; margin-left:20px; margin-right:20px;  margin-bottom:20px; border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;    

}

.vendu_accueil
{
position:absolute;
top:0%; width:100px;
   padding:5px;
   color:black ;
    z-index:2;
    font-size:9pt;
    margin-top:10px;
    background-color:yellow;
    opacity: .5;

}

.exclusivite_accueil
{
position:absolute;
top:52%; width:100px;
   color:white; 
   padding-left:5px;
   padding-right:5px; 
   ; z-index:2;
   font-size:9pt;
   margin-top:10px;
   background-color:#ee4950;
   box-shadow: 1px 1px 12px #555;
            

}

.nouveaute_accueil
{
position:absolute;
top:52%;
margin-left:120px; 
width:100px;
   color:white; 
   padding-left:5px;
   padding-right:5px; 
    z-index:2;
    font-size:9pt;
    margin-top:10px;
    background-color:#ee4950;
    box-shadow: 1px 1px 12px #555;

}



.vendu_detail
{


position:relative;
 display:inline-block;
top:0%; 
width:100px;
   padding:5px;
   color:white ;
    z-index:1;
    font-size:9pt;
    margin-top:10px;
    background-color:#ee4950;;
    opacity: 1;
    

}

.exclusivite_detail
{
position:relative;
 display:inline-block;
top:0%; 
width:100px;
   padding:5px;
    color:white ;
     z-index:1;
    font-size:9pt;
    margin-top:10px;
    background-color:#ee4950;;
    opacity: 1;
            

}

.nouveaute_detail
{
position:relative;
 display:inline-block;
top:0%; 
width:100px;
   padding:5px;
    color:white ;
     z-index:1;
    font-size:9pt;
    margin-top:10px;
    background-color:#ee4950;;
    opacity: 1;

}



.btn_avance
{

padding-left:5px; 
text-align:center;
 width: 30px;
 border-radius: 20px; 
  height: 30px;
background:#5bc0de;
opacity: 0.33;

}

.btn_recul
{

padding-right:5px; 
text-align:center;
 width: 30px;
 border-radius: 20px; 
  height: 30px;
background:#5bc0de;
opacity: 0.33;

}

.btn_avance:hover
{

opacity: 1;
}

.btn_recul:hover
{

opacity: 1;
}


.contact2
{

    width:400px; float:left;text-align:center; margin-top:20px;

}

.logo2{
    display:inline-block;
     width:400px;
     visibility: visible;


}

.image_logo
{
    width:auto;
     height:104px;
}


.form-control_1
{

width:200px;
height: auto;
float:left;
margin-left:30px;


}




.conteneur_slide_detail_bien
{

width:590px;
height:auto;
text-align:center;
background-color:white;
display:inline-block

}



.conteneur_btn_rechercher
{
margin-left:20px; 

float:right;
padding:5px;
padding-left:20px;
padding-right:20px;



}
.conteneur_btn_rechercher_1
{
margin:5px; 
  float:right;
    width:auto; 
       font-size: 2rem;
       color: #0D709E;

}

.sous_conteneur_diapo
{
vertical-align: middle;    

width:100%;
display: table-cell;

}
.conteneur_bouton_recule_slide 
{
  
margin-right:10px;
    
vertical-align: middle;

display: inline-block;
width:auto;
height: 100%;
cursor: pointer;
visibility:visible;


}

.conteneur_bouton_avance_slide
{
     margin-left:10px;
    
vertical-align: middle;

display: inline-block;
width:auto;
height: 100%;
cursor: pointer;
visibility:visible;

}
.conteneur_slide
{
width:auto;
 display: inline-block;
 text-align: center;
 vertical-align: middle;

}



.info_calcul
{

vertical-align:top;    width:465px; display:inline-block; 
}




.conteneur_photo_detail_bien
{

background-color:white;
height:400px;
 width:100%; 
 display:block; 
 text-align:center;

}


.notre_agence_1
{
 text-align:center; margin-left:10px;    width:49%; display:inline-block; 

}
.notre_agence_2
{
 
    vertical-align:top;    width:480px; display:inline-block;
}



.font1
{
 margin-top: 20px;   
 text-align: center;
 font-size: 18px; 
 font-weight:normal;  
  font-family: Arial, Helvetica, sans-serif; 
  color: #666;

}

.font2{
    text-align: center;
margin-top:10px; 
font-size: 18px;
 font-weight:normal;  
font-family: Arial, Helvetica, sans-serif; color:#0d709d;
}

.font3
{
    text-align: center;
margin-top:5px; 
font-size: 16px;
 font-weight:normal;
         font-family: Arial, Helvetica, sans-serif;
          color:#666;

}

.font4{
text-align: center;
margin-top:5px;
 font-size: 12px;
  font-weight:normal;
          font-family: Arial, Helvetica, sans-serif;
           color:#666;
}








.photo_derniere_annonce
{
max-height: 200px;
width:100%;
height:180px;

}

.label_derniere_annonce
{
    float:left ; 
    width:500px;  
    font-size: 2rem;
    color: #0D709E;
    margin-top:5px;
}
.label_notre_agence
{
    margin-left:10px;
     margin-right:10px;
display:block;

width:100%;  
font-size: 2rem;
color: #0D709E;
margin-top:5px;

}



#navigation{

    display: block;
   visibility: visible;

    padding-top: 10px;
    display: inline-block
}

#navigation li {
    display: inline;
    text-align: center;
    border-radius: 5px 10px 0 5px;
}

#navigation li a {
    padding: 4px 20px;
    /* background: #c00 ;*/
    color: #fff;
    /*  border: 1px solid #600 ;*/
    border-radius: 5px;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
}

#navigation li a:hover,
#navigation li a:focus,
#navigation li a:active {
    background: #0d709f;
    text-decoration: none;
}

#menu {
    
    text-align: center;
    height: auto;
    padding: 1px;
    background-color: #333399;
    box-shadow: 0px -2px 3px 0px #919191;
    border-bottom-width: thin;
    border-bottom-style: solid;
    border-bottom-color: #FFF;
}

.btn_menu_wdr
{
    cursor: pointer;

    margin:10px; 
       color:white;
        font-size:20px;
         
           height:auto;
           visibility: hidden;
           display: none;
}

.btn_rechercher_wdr
{
    cursor: pointer;

    margin:10px; 
       color:white;
        
         
           height:auto;
           visibility: hidden;
           display: none;
}



/*********************/

.diaporama {
    
    background-color: #0d709f;
   
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    display: block;
}

.img {
    height: 450px;
    width: auto;
    min-width: 2000px;
    position: absolute;
    top: 100px;
    right: 0px;
    width: 100%;
}

.btn_voir_tous_les_offres {
    margin-top: 15px;
    margin-right: 15px;
    text-align: center;
    float: right;
    font-size: 12px;
    padding: 6px;
    color: #FFF;
    background-color: #5bc0de;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
    border-radius: 4px 4px 4px 4px;
}

.btn_voir_tous_les_offres:hover {
    cursor: pointer;
    box-shadow: 1px 1px 12px #555;
}

.info_detail_bien{

      display:inline-block; 
      vertical-align:top;
       padding:10px; 
        width:400px; 
         height:300px; 
}

.info_detail_bien_photo
{

display:inline-block;  
width:400px;
height:300px;

}





.derniere_annonce {
    text-align: left;
    float: left;
    color: #0d709e;
    font-size: 24px;
    padding-left: 20px;
    padding-top: 20px;
}

#info2:hover {
background-color: #333399;
display: none;
}


/********debut*slideshow***********/

.mySlides {
    display: none
}

img {
    vertical-align: middle;
   
}


/* Slideshow container */

.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
}

.mySlides_container {
    height: 350px;
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    border: thin solid #FFF;
    border-radius: 5px 5px 5px 5px;
}

.box_1 {
    
    width: 100%;
    height: auto;
    
    border-left: 5px solid white;
    border-right: 5px solid white;
    font-family: Arial, Helvetica, sans-serif;
    color: #666;
   
    font-size: 10px;
    font-weight: bold;
    display:inline-block;
    
}


/* Next & previous buttons */

.prev {
    left: 0;
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    color: #7f7f7f;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}


/* Position the "next button" to the right */

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}


/* On hover, add a black background color with a little bit see-through */

.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
    background-color: #0d709f;
    opacity: 0.33;
}


/* Caption text */

.text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}


/* Number text (1/3 etc) */

.numbertext {
    font-size: 11px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    opacity: .5;
}


/* The dots/bullets/indicators */

.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active,
.dot:hover {
    background-color: #717171;
}


/* Fading animation */

.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1s;
    animation-name: fade;
    animation-duration: 1s;
}

@-webkit-keyframes fade {
    from {
        opacity: .4
    }
    to {
        opacity: 1
    }
}

@keyframes fade {
    from {
        opacity: .4
    }
    to {
        opacity: 1
    }
}


/* On smaller screens, decrease text size */





@media only screen and (max-width: 300px) {
    .prev,
    .next,
    .text {
        font-size: 11px
    }
}


/********debut*slideshow***********/


/* définition de l'animation sur les images */

.diaporama .img {
    animation: fade-in-out 10s ease infinite 0s;
    opacity: 0;
}


/* définition délai des images */

.diaporama .img:nth-child(1) {
    animation-delay: 0s;
}

.diaporama .img:nth-child(2) {
    animation-delay: 5s;
}


/* définition des étapes */

@keyframes fade-in-out {
    0%,
    100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
}


/*************************/


/* On smaller screens, decrease text size */

@media only screen and (max-width: 300px) {
    .text {
        font-size: 11px
    }
}

@media only screen and (min-width: 768px) {
   
}

#pied_page_1 {
    background-color: #e8e1d9;
     
    text-align: center;
    padding: 20px;
    display: block;
    display: none;
}

.conteneur_pied_page_2
{
    text-align: left;
    display: inline-block;
width: 978px;
height: auto;

background-color:white;
border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;
margin-bottom: 10px;
}


#pied_page_2 {
    width: 978px;
    
    background-color: #e8e1d9;
    text-align: center;
    padding: 20px;
    display: block;
    border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;
}

#contenneur_barre_recherche{

 margin-top:10px;
 text-align:center;
 position: relative;
 
 left: 0;
 display:inline-block; 
 width:100%;
 height:auto;


}

#barre_recherche {

    font-size: 72pt;
    
    padding:15px;
    width: 978px;
    height: auto;
    z-index: auto;
    background-color: #FFF;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 1px 1px 12px #555;
    display: inline-block;
}


#conteneur_detail_bien{
text-align:center;
 position: relative;	
 top: 0px; 
 left: 0;
 display:block; 
 width:100%;
 

}

.conteneur_derniere_annonce{
 margin-top:20px;   
 text-align:center;
 position: relative;
 
 
 display:inline-block; 
 width:100%;
 height:auto;
 

}

.sous_conteneur_deniere_annonce
{
    
    text-align: center;
   
    width:978px;
    height:auto;
    z-index: auto;
    background-color: #FFF;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 1px 1px 12px #555;
    display: inline-block;
  

}
.sous_conteneur_deniere_annonce2
{
text-align: center;
    padding:15px;
    width:auto;
    height:auto;
    z-index: auto;
   
    
    display: inline-block;
    padding:40px;

}


.conteneur_resultat_recherche_bien
{
position: relative;
text-align:center;
 	
/*top:-600px;*/

 
 width:100%;
 
 height: auto;


}
.sous_resultat_recherche_bien
{
text-align: left;
width: auto;
height: auto;


background-color:white;

border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;
padding:5px;
margin:10px;
padding-bottom: 20px;
margin-bottom:30px;


}

#resultat_recherche_bien
{
z-index: 200;
display: inline-block;
width: auto;

height: auto;
background-color:white;
border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;
padding:5px;

}

.conteneur_info_agence
{

text-align:center;
 position: relative;
 
 left: 0;
 display:inline-block; 
 width:100%;
 height:auto;


}


.sous_conteneur_info_agence
{
text-align: left;
    display: inline-block;
width: 978px;
height: auto;

background-color:white;
border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;


}



.sous_conteneur_detail_bien
{


display: inline-block;
width: auto;

background-color:white;
border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;
padding:5px;

}



.conteneur_contact
{
padding:8px;
float:left;
margin-left:10px;
width:280px; 
height:auto;

 
border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;
    

background-color:white;

}

#detail_bien {
    float:left;
    padding:15px;
    width: 698px;
   height:auto;
    z-index: auto;
    background-color:white;
    border: #333399 solid 1px;
    border-radius: 5px 5px 5px 5px;
    
    
}


.diaporama_detail_bien{
    display: inline-block;

background-color:white;
height: auto;
width:100%;
max-width: 978px;


}


.description_bien
{
font-size: 10pt;
display: inline-block;
background-color:#e8e1d9; 

width: 32%;

padding:1px;
margin-top:5px;


}


.titre_detail_bien
{

    font-family: Arial, Helvetica, sans-serif;
font-size: 14pt;
font-size: 1.16667rem;
color: #0D709E;
vertical-align: middle;
           
}

.sous_titre_detail_bien
{
width:100%;
    font-family: Arial, Helvetica, sans-serif; font-size: 9pt;
     font-size: 14px;
      color: #FFFFFF; text-align: left; 
      vertical-align: middle;
       background-color: #5BC0DE;
        border-radius: 0; 
        padding-left:5px;
        padding-top:2px;
        padding-bottom:2px;
        margin-top:20px;
           
}


#annonce {
    width: 978px;
    box-shadow: 1px 1px 12px #555;
    height: 448px;
    height: auto;
    z-index: auto;
    background-color: #FFF;
    border-radius: 5px 5px 5px 5px;
    display: inline-block;
}

#texte_page_accueil {
    width: 978px;
    height: 448px;
    height: auto;
    z-index: auto;
    padding-top: 20px;
    border-top: thin solid #FFF;
    border-bottom: thin solid #FFF;
    display: inline-block;
    font-size: 14px;
}



@media screen and (max-width: 1000px) {
  
 #texte_page_accueil {
    width: auto;
    height: 448px;
    height: auto;
    z-index: auto;
    padding-top: 20px;
    border-top: thin solid #FFF;
    border-bottom: thin solid #FFF;
    display: inline-block;
    font-size: 14px;
}



.diaporama{

    display: none;
    
   
}


#contenneur_barre_recherche{/*WDR*/
 margin-top:0px;
text-align:center;
 
/*top: 200px; */
top: 0px;
left: 0;
display:none; 
width:100%;
height:auto;

 background-color:white;


}

#barre_recherche {
    font-size: 40pt;
    padding:15px;
    width: 100%;
    height: auto;
    z-index: auto;
    background-color: #FFF;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 1px 1px 12px #555;
   


    font-size: large;
}



#conteneur_detail_bien{
top: 0px; 
text-align:center;
 position: static;	
 
 left: 0;
 display:inline-block; 
 width:auto;
 height:auto;
 background-color: white;

}
.diaporama_detail_bien{

background-color:white;
height: auto;
width:100%;
max-width: 978px;

}

#detail_bien {
    padding:15px;
    width: auto;
    height: auto;
    z-index: auto;
    background-color:white;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 1px 1px 12px #555;
    display: inline-block;
}

.conteneur_derniere_annonce{
   
 text-align:center;
 /*position: relative;*/
 
 
 display:inline-block; 
 width:100%;
 height:auto;
 background-color:white;
 

}

.sous_conteneur_deniere_annonce
{
    
    text-align: center;
   
    width:100%;
    height:auto;
    z-index: auto;
    background-color: #FFF;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 1px 1px 12px #555;
    display: inline-block;
    
  

}
.sous_conteneur_deniere_annonce2
{
text-align: center;
    padding:15px;
    width:100%;
    height:auto;
    z-index: auto;
   
    
    display: inline-block;
    padding:40px;

}

.box_1 {
    
    width: 100%;
    height: auto;
    
    border-left: 5px solid white;
    border-right: 5px solid white;
    font-family: Arial, Helvetica, sans-serif;
   
    font-size: 40px;
    font-weight: bold;
    display:inline-block;
    /*background-color: hotpink;*/
    
    
}


.multiple-items{

all: initial;   
height:auto;
width: 100%;



}

.logo2{

     display:none;
     visibility:hidden;


}

.logo{

     width: auto;
     height: auto;

     


}


.image_logo
{
    width:100%;
     height:208px;
}
.font-size
{

    font-size: 44pt;
    color: hotpink;
}
.photo_derniere_annonce
{
width:100%;
max-height: 100%;
height:auto;

}

.font1
{
text-align: center;
 font-size: 40px; 
 font-weight:normal;  
  font-family: Arial, Helvetica, sans-serif; 
  color: #666;

}

.font2{
    text-align: center;
margin-top:10px; 
font-size: 30px;
 font-weight:normal;  
font-family: Arial, Helvetica, sans-serif; color:#0d709d;
}
.font3
{
    text-align: center;
margin-top:5px; 
font-size: 30px;
 font-weight:normal;
         font-family: Arial, Helvetica, sans-serif;
          color:#666;

}


.font4{
text-align: center;
margin-top:5px;
 font-size: 30px;
  font-weight:normal;
          font-family: Arial, Helvetica, sans-serif;
           color:#666;
}

.form-control_1
{

width:100%;
height: auto;
float:left;
margin-left:30px;


}

.conteneur_btn_rechercher_1
{
margin:5px; 
  float:none;
    width:auto; 
       font-size: 2rem;
       color: #0D709E;

}



.conteneur_btn_rechercher
{
    text-align: center;
    margin-left:0px; 
    text-align: center;
    width:100%;
    height: auto;


float: none;
padding:5px;
padding-left:0px;
padding-right:0px;



}


.label_derniere_annonce
{
    text-align: center;
    float:left ; 
    width:100%;  
    font-size: 40px;
    color: #0D709E;
    margin-top:5px;
}

.label_notre_agence
{
    
display:block;

width:100%;  
font-size: 2rem;
color: #0D709E;
margin-top:5px;

}








/********** debut menu *************/


#navigation li {

    display: block;
    text-align: center;
    border: white solid 1px;
    border-radius: 5px 5px 5px 5px;
    width:100%;
    height: auto;



}



#navigation li a {
    margin: 0px;
    /* background: #c00 ;*/
    color: #fff;
    /*  border: 1px solid #600 ;*/
    border-radius: 5px;
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    font-size: 40px;
    
}

li {
margin-top: 10px;
padding:40px;



}

#navigation li a:hover,
#navigation li a:focus,
#navigation li a:active {
    background: #0d709f;
    text-decoration: none;
}

#menu {
    
    text-align: left;
    height: auto;
    padding: 1px;
    background-color: #333399;
    box-shadow: 0px 0px 0px 0px #919191;
    border-bottom-width: thin;
    border-bottom-style: solid;
    border-bottom-color: #FFF;
}


#navigation{
    
/*visibility: hidden;*/
    display:none;


    padding: 5px;
    
    text-align: center;

    padding: 5px;
    
    text-align: center;
}



    .btn_menu_wdr
{
    height: 50px;
    cursor: pointer;

    margin:10px; 
    
       color:white;
        font-size:40px;
          
           height:auto;
           visibility:visible;
           display:inline-block;
}

.btn_rechercher_wdr
{
    vertical-align:top;
    height: 90px;
    cursor: pointer;

    margin:10px; 
    margin-top: 20px;
    
       color:white;
        
          
           height:auto;
           visibility:visible;
           display:inline-block;
}

/********** fin menu *************/


.sous_conteneur_info_agence
{
text-align: center;
    display: inline-block;
width: 100%;
height: auto;

background-color:white;
border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;



}

.conteneur_pied_page_2
{
text-align: center;
    display: inline-block;
width: 100%;
height: auto;

background-color:white;
border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;



}

#pied_page_2 {
    width: auto;
    
    background-color: #e8e1d9;
    text-align: center;
    padding: 20px;
    display: block;
}
.notre_agence_1
{
 text-align:center;
  margin-left:10px; 
  margin-right:10px; 
     width:100%; display:block; 

}
.notre_agence_2
{
    text-align:left;

 padding:0px;
     
      width:100%; 
      display:block;
}




.titre_detail_bien
{

    font-family: Arial, Helvetica, sans-serif;
font-size: 40px;

color: #0D709E;
vertical-align: middle;
           
}

.sous_titre_detail_bien
{
width:100%;
    font-family: Arial, Helvetica, sans-serif; 
     font-size: 40px;
      color: #FFFFFF; text-align: left; 
      vertical-align: middle;
       background-color: #5BC0DE;
        border-radius: 0; 
        padding-left:5px;
        padding-top:2px;
        padding-bottom:2px;
        margin-top:20px;
           
}



.sous_conteneur_diapo
{
   

width:100%;
display: block;

}
.conteneur_bouton_recule_slide 
{
  
margin-right:10px;
    
vertical-align: middle;

display: none;
width:auto;
height: 100%;
cursor: pointer;
visibility:hidden;


}

.conteneur_bouton_avance_slide
{
margin-left:10px;
    
vertical-align: middle;

display: none;
width:auto;
height: 100%;
cursor: pointer;
visibility:hidden;

}
.conteneur_slide
{
    width:100%;
 display: inline-block;
 vertical-align: middle;
 text-align: center;

}


.conteneur_slide_detail_bien
{

width:100%;
height:auto;
text-align:center;
background-color:white;
display:inline-block

}


.contact1{

float:left;  
 text-align:center; margin-left:10px; padding:20px;
     width:auto ;
     margin-top:20px;
      margin-left:20px;
       margin-right:20px;
         margin-bottom:20px;
          border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;
font-size: 40px;    

}

.contact2
{

    width:100%; float:left;text-align:center; margin-top:20px;

}

.formulaiare_calcul
{
text-align:center; 
margin-left:10px;   
 width:100%; 
 display:inline-block; 
 padding:10px;


}

.info_calcul
{

vertical-align:top;  
  width:100%; 
  display:inline-block; 
}

.conteneur_photo_detail_bien
{

background-color:white;
height:auto;
 width:100%; 
 display:block; 
 text-align:center;

}



.conteneur_contact
{
padding:8px;
float:left;
margin-top: 10px;
margin-left:10px;
margin-bottom: 20px;
width:100%; 
height:auto;

 
border-radius: 5px 5px 5px 5px;
box-shadow: 1px 1px 12px #555;
    

background-color:white;

font-size: 40px;

}

.description_bien
{
    padding: 10px;
text-align: left;
font-size: 40px;
display: inline-block;
background-color:#e8e1d9; 

width: 100%;

padding:1px;
margin-top:5px;


}





.vendu_accueil
{
position:relative;
top:0%; width:auto;
   padding:5px;
   color:black ;
    z-index:2;
    font-size:40px;
    margin-top:10px;
    background-color:rgb(218, 31, 77);
    opacity: .5;

}

.exclusivite_accueil
{
position:relative;
top:52%; width:auto;
   color:white; 
   padding-left:5px;
   padding-right:5px; 
   ; z-index:2;
   font-size:40px;
   margin-top:10px;
   background-color:#ee4950;
   box-shadow: 1px 1px 12px #555;
            

}

.nouveaute_accueil
{
position:relative;
top:52%;
margin-left:0px; 
width:auto;
   color:white; 
   padding-left:5px;
   padding-right:5px; 
    z-index:2;
    font-size:40px;
    margin-top:10px;
    background-color:#ee4950;
    box-shadow: 1px 1px 12px #555;

}


.vendu_detail
{
position:relative;
top:52%;
margin-left:0px; 
width:auto;
   color:white; 
   padding-left:5px;
   padding-right:5px; 
    z-index:2;
    font-size:40px;
    margin-top:10px;
    background-color:#ee4950;
    box-shadow: 1px 1px 12px #555;
    opacity: 1;


}

.exclusivite_detail
{
position:relative;
top:52%;
margin-left:0px; 
width:auto;
   color:white; 
   padding-left:5px;
   padding-right:5px; 
    z-index:2;
    font-size:40px;
    margin-top:10px;
    background-color:#ee4950;
    box-shadow: 1px 1px 12px #555;
 opacity: 1;
            

}

.nouveaute_detail
{
position:relative;
top:52%;
margin-left:0px; 
width:auto;
   color:white; 
   padding-left:5px;
   padding-right:5px; 
    z-index:2;
    font-size:40px;
    margin-top:10px;
    background-color:#ee4950;
    box-shadow: 1px 1px 12px #555;
 opacity: 1;

}

.info_detail_bien
{
width: 100%;




}

.info_detail_bien_photo
{

display:block; 
 width:auto;
 height: 100%;
 
}


}






